<div class="test-report-view inner-view">
  <div class="view-header with-breadcrumbs">
    <app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
    <div class="align-container space-between">
      <h1 class="align-container small-spacing">
        <span>{{testReport?.fileName || '&nbsp;'}}</span>
        <button class="information" (click)="showInfo()"></button>
      </h1>
      <div class="controls">
        <button class="primary" (click)="editTestReport()" *ngIf="testReport?.status !== TestReportStatus.DELETED">Edit</button>
        <button class="primary button-delete" (click)="deleteTestReport()" *ngIf="testReport?.status !== TestReportStatus.DELETED">Delete</button>
        <button class="secondary button-export" (click)="downloadTestReport()">Download</button>
      </div>
    </div>
  </div>
  <div class="view-section view-container">
    <mat-tab-group mat-align-tabs="start" *ngIf="testReport">
      <mat-tab label="Info">
        <table class="main-data">
          <tbody>
            <tr>
              <th class="label">Type</th>
              <td class="value">{{testReport.type?.name || '&mdash;'}}</td>
              <td class="spacer"></td>
              <th class="label">Status</th>
              <td class="value capitalize">{{testReport.status || '&mdash;'}}</td>
            </tr>
            <tr>
              <th class="label">Models Group</th>
              <td class="value">
                <a class="button link-button" *ngIf="testReport.modelGroup"
                  [routerLink]="['/reports', 'models-group', testReport.modelGroup.id]">{{testReport.modelGroup.name}}</a>
                <ng-container *ngIf="!testReport.modelGroup">&mdash;</ng-container>
              </td>
              <td class="spacer"></td>
              <th class="label">Author</th>
              <td class="value">{{testReport?.author || '&mdash;'}}</td>
            </tr>
            <tr>
              <th class="label">Master-model</th>
              <td class="value">
                <a class="button link-button" *ngIf="testReport.masterModel"
                  [routerLink]="['/administration', 'device-models', testReport.masterModel.id]">{{testReport.masterModel.name}}</a>
                <ng-container *ngIf="!testReport.masterModel">&mdash;</ng-container>
              </td>
              <td class="spacer"></td>
              <th class="label">Created</th>
              <td class="value">{{testReport.createdAt ? (testReport.createdAt | date:DATE_TIME) : '&mdash;'}}</td>
            </tr>
            <tr>
              <th class="label">Wi-Fi Module</th>
              <td class="value">{{testReport.wifiModuleVersion?.version || '&mdash;'}}</td>
              <td class="spacer"></td>
              <th class="label">Editor</th>
              <td class="value">{{testReport.updatedBy || '&mdash;'}}</td>
            </tr>
            <tr>
              <th class="label">EPP Json</th>
              <td class="value">{{testReport.eppVersion || '&mdash;'}}</td>
              <td class="spacer"></td>
              <th class="label">Updated</th>
              <td class="value">{{testReport.updatedAt ? (testReport.updatedAt | date:DATE_TIME) : '&mdash;'}}</td>
            </tr>
            <tr>
              <th class="label shrink-cell">iOS version</th>
              <td class="value shrink-cell">{{testReport.ios ? (testReport.iosVersion || '&mdash;') : '&mdash;'}}</td>
              <td class="spacer"></td>
              <th class="label">Comment</th>
              <td class="value" rowspan="2">{{testReport.comment || '&mdash;'}}</td>
            </tr>
            <tr>
              <th class="label">Android version</th>
              <td class="value">{{testReport.android ? (testReport.androidVersion || '&mdash;') : '&mdash;'}}</td>
              <td class="spacer"></td>
              <td colspan="2"></td>
            </tr>
          </tbody>
        </table>
      </mat-tab>
      <mat-tab label="Models">
        <perfect-scrollbar>
          <div class="scroll-area">
            <table class="results-table models-table">
              <thead>
                <tr>
                  <th class="wide-column">Name</th>
                  <th class="wide-column nowrap">Logic Json</th>
                  <th class="narrow-column nowrap master-column">Master Model</th>
                  <th class="narrow-column warning-line"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let model of testReport.models">
                  <td class="wide-column">
                    <a class="button link-button" [routerLink]="['/administration', 'device-models', model.model?.id]" *ngIf="model.model?.name">{{model.model?.name}}</a>
                    <span *ngIf="!model.model?.name">&mdash;</span>
                  </td>
                  <td class="wide-column break-all">{{model.logicConfig?.name || '&mdash;'}}</td>
                  <td class="narrow-column model-main master-column">
                    <div class="model-main-flag" [class.main-flag]="model.main" [class.non-main-flag]="!model.main"></div>
                  </td>
                  <td class="narrow-column warning-line">
                    <div class="attention-icon-container" *ngIf="model.model?.patchConfirmed === DeviceModelGroupModelPatchConfirmState.PATCH_NOT_CONFIRMED"
                         triggers="mouseenter:mouseleave" [placement]="['top-right', 'bottom-right']" ngbPopover="Patch for this model is not confirmed">
                      <div class="button attention-bubble model-patch-confirmed" (click)="confirmModelPatch(model.model)" >
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <app-no-data *ngIf="!testReport || !testReport.models?.length"></app-no-data>
          </div>
        </perfect-scrollbar>
      </mat-tab>
    </mat-tab-group>
  </div>
  <ngx-loading [show]="loading">
  </ngx-loading>
</div>
<ng-template #information>
  <div><b>Test Report Card</b> – all information about the test reports, including the file, characteristics, their status and mappings to smart device models:</div>
  <ul class="less-space">
    <li><b>Type</b> – type of testing that was performed according to the test report (Regression/Functional)</li>
    <li><b>Models group</b> – name of the model group to which the test report belongs</li>
    <li><b>Master-model</b> – the name of the model on which the test was actually performed</li>
    <li><b>Epp (IC)</b> – an ID of a motherboard of a device, which this file is available for</li>
    <li><b>iOS version</b> – iOS app version used in testing</li>
    <li><b>Android version</b> – Android app version used in testing</li>
    <li><b>Status</b> – test report status (Active/ Inactive/ Deleted/ Draft)</li>
    <li><b>Author</b> – the name of the user who uploaded the test report</li>
    <li><b>Created</b> – date and time when the report was added to the admin console</li>
    <li><b>Editor</b> – the name of the user who last edited the test report</li>
    <li><b>Updated</b> – date and time of the last report editing in the admin console</li>
    <li><b>Comment</b> – comment on the test report
    <li><b>Models</b> – models in the group of models on which the testing was performed, information is presented for each model:
      <ul>
        <li><b>NAME</b> – device model name</li>
        <li><b>LOGIC JSON</b> – version of the JSON that was used in testing</li>
        <li><b>MASTER MODEL</b> – characteristic that determines on which model the test was actually performed. Only one model in the list can have this characteristic.</li>
      </ul>
    </li>
  </ul>
</ng-template>
